export default {
  address: {
    contactNumberLength: 0,
    contactNumberMinLength: 0,
    emailRequired: false,
    hidePin: false,
    hideCountry: false,
    fixCountry: '',
    pinCodeRequired: false,
    pinCodeLength: 0,
    pinCodeDigitOnly: false,
  },
  allowInsufficientPoint: false,
  app: 'cla',
  appName: 'Clarins',
  drawerBtnLabel: 'common.filter._1',
  freshchat: {
    isShow: true,
    pages: 'all',
  },
  footer: {
    dynamicLogo: false,
  },
  formatHtml: true,
  header: {
    dynamicBg: true,
    dynamicLogo: true,
    firstLogo: true,
    secondLogo: '',
    pointDetail: true,
    notification: false,
    showDepartmentInProfile: false,
  },
  hideRewardsTnC: true,
  infiniteStockEVoucher: true,
  languageAvailable: ['en'],
  languageLabel: {
    en: 'English',
  },
  leftNavigate: [
    'home',
    'appreciate',
    'feeds',
    'rewards',
    'calendar',
    'settings',
  ],
  modules: {
    receipts: false,
    leaderboard: false,
    flexiBenefits: false,
  },
  panel: {
    link: ['/profile'],
    support: false,
  },
  pointDetailTitle: 'common.pointDetails',
  rating: false,
  recognitionRecommendation: true,
  registerNewAccount: false,
  removeRewardRedemption: true,
  rewards: {
    detailQty: 2,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: false,
      pointsCollapsible: false,
      rating: false,
    },
  },
  settings: {
    hideMyAppreciationMessagesToggle: true,
    makeMyBirthdayPublicToggle: true,
    makeMyAnniversaryPublicToggle: true,
    makeMyProfilePublicToggle: true,
    passwordChangeShow: true,
    passwordChangeStyle: 'inline',
  },
  showFooterContactUsDialog: true,
  showPointCategories: true,
  transactionHistoryRewardNameTranslatable: true,
};
